import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

const ImageComparison = ({ beforeSrc, afterSrc, title, description }) => {
  const { t } = useTranslation();
  const [sliderPosition, setSliderPosition] = useState(50);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeImage, setActiveImage] = useState("before");
  const [isLandscape, setIsLandscape] = useState(false);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showRotationHint, setShowRotationHint] = useState(false);

  const containerRef = useRef(null);
  const sliderRef = useRef(null);
  const imageRef = useRef(null);
  const modalImageRef = useRef(null);

  const isMobileRef = useRef(false);

  useEffect(() => {
    const checkMobile = () => {
      isMobileRef.current =
        (window.innerWidth <= 768 || window.innerHeight <= 768) &&
        ("ontouchstart" in window || navigator.maxTouchPoints > 0);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useLayoutEffect(() => {
    const handleOrientation = () => {
      const isLandscape = window.matchMedia("(orientation: landscape)").matches;
      setIsLandscape(isLandscape);
      setScale(1);
      setPosition({ x: 0, y: 0 });
    };

    window.addEventListener("resize", handleOrientation);
    handleOrientation();

    return () => {
      window.removeEventListener("resize", handleOrientation);
    };
  }, []);

  useEffect(() => {
    const updateImageSize = () => {
      if (containerRef.current && imageRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const viewportHeight = window.innerHeight;
        const maxHeight = viewportHeight * 0.85;

        const img = imageRef.current;
        const naturalRatio = img.naturalWidth / img.naturalHeight;

        let width = containerWidth;
        let height = containerWidth / naturalRatio;

        if (height > maxHeight) {
          height = maxHeight;
          width = height * naturalRatio;
        }

        setImageSize({ width, height });
      }
    };

    const img = new Image();
    img.onload = updateImageSize;
    img.src = beforeSrc;

    window.addEventListener("resize", updateImageSize);
    return () => window.removeEventListener("resize", updateImageSize);
  }, [beforeSrc]);

  const handleMouseMove = (event) => {
    if (!isMobileRef.current && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      setSliderPosition((x / rect.width) * 100);
    }
  };

  const handleTouchMove = (event) => {
    if (!isMobileRef.current) {
      event.preventDefault();
      handleMouseMove(event.touches[0]);
    }
  };

  useEffect(() => {
    if (isModalOpen && !isLandscape) {
      setShowRotationHint(true);
      const timer = setTimeout(() => {
        setShowRotationHint(false);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [isModalOpen, isLandscape]);

  const handleImageClick = () => {
    if (isMobileRef.current) {
      setIsModalOpen(true);
      setActiveImage("before");
      setScale(1);
      setPosition({ x: 0, y: 0 });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const toggleImage = () => {
    setActiveImage(activeImage === "before" ? "after" : "before");
    setScale(1);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoom = (event) => {
    event.preventDefault();
    const newScale = scale + event.deltaY * -0.01;
    setScale(Math.min(Math.max(1, newScale), 3));
  };

  const handlePan = (event) => {
    if (scale > 1) {
      const { movementX, movementY } = event;
      setPosition((prev) => ({
        x: prev.x + movementX,
        y: prev.y + movementY,
      }));
    }
  };

  const beforeOpacity = Math.min(1, sliderPosition / 50);
  const afterOpacity = Math.min(1, (100 - sliderPosition) / 50);

  return (
    <div className="flex flex-col mb-12" ref={containerRef}>
      {title && (
        <h3 className="text-xl font-semibold text-indigo-700 mb-4">
          {t(title)}
        </h3>
      )}
      {description && <p className="text-gray-600 mb-4">{t(description)}</p>}
      <div
        className="relative mx-auto rounded-xl overflow-hidden shadow-lg"
        ref={sliderRef}
        style={{
          width: `${imageSize.width}px`,
          height: `${imageSize.height}px`,
        }}
        onClick={handleImageClick}
      >
        <img
          ref={imageRef}
          alt={t("imageComparison.beforeAlt")}
          src={beforeSrc}
          className="absolute top-0 left-0 w-full h-full object-contain"
        />
        {!isMobileRef.current && (
          <>
            <div
              className="absolute top-0 left-0 w-full h-full overflow-hidden"
              style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
            >
              <img
                alt={t("imageComparison.afterAlt")}
                src={afterSrc}
                className="absolute top-0 left-0 w-full h-full object-contain"
              />
            </div>
            <div
              className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
              style={{ left: `${sliderPosition}%` }}
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md">
                <svg
                  className="w-4 h-4 text-indigo-700"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                  <path d="M15.41 16.59L10.83 12 15.41 7.41 14 6l-6 6 6 6 1.41-1.41z" />
                </svg>
              </div>
            </div>
            <div
              className="absolute top-5 left-5 bg-white text-indigo-700 px-4 py-2 rounded-full text-base font-semibold transition-opacity duration-200 shadow-md"
              style={{ opacity: beforeOpacity }}
            >
              {t("imageComparison.before")}
            </div>
            <div
              className="absolute top-5 right-5 bg-white text-indigo-700 px-4 py-2 rounded-full text-base font-semibold transition-opacity duration-200 shadow-md"
              style={{ opacity: afterOpacity }}
            >
              {t("imageComparison.after")}
            </div>
          </>
        )}
        {isMobileRef.current && (
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-indigo-700 px-3 py-1 rounded-full text-sm font-semibold shadow-md">
            {t("imageComparison.tapToZoom")}
          </div>
        )}
      </div>

      {isMobileRef.current && isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
          <div className="relative w-full h-full flex flex-col justify-between">
            <div
              className={`absolute top-0 left-0 right-0 flex justify-between items-center p-4 ${
                isLandscape ? "" : "bg-gradient-to-b from-black to-transparent"
              } z-10`}
            >
              <button
                className="text-white text-2xl font-bold"
                onClick={closeModal}
              >
                {t("imageComparison.modal.close")}
              </button>
            </div>
            <div
              className="w-full h-full overflow-hidden"
              onWheel={handleZoom}
              onMouseMove={handlePan}
              onTouchMove={(e) => {
                const touch = e.touches[0];
                handlePan({
                  movementX: touch.clientX,
                  movementY: touch.clientY,
                });
              }}
            >
              <img
                ref={modalImageRef}
                src={activeImage === "before" ? beforeSrc : afterSrc}
                alt={
                  activeImage === "before"
                    ? t("imageComparison.before")
                    : t("imageComparison.after")
                }
                className="w-full h-full object-contain transition-transform duration-200 ease-out"
                style={{
                  transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                }}
              />
            </div>
            <div
              className={`absolute ${
                isLandscape
                  ? "top-1/2 left-4 transform -translate-y-1/2"
                  : "top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              } bg-black bg-opacity-50 text-white px-6 py-3 rounded-full text-2xl font-bold shadow-lg transition-opacity duration-300 ease-in-out z-10`}
            >
              {activeImage === "before"
                ? t("imageComparison.before")
                : t("imageComparison.after")}
            </div>
            <div
              className={`absolute ${
                isLandscape ? "bottom-4 right-4" : "bottom-0 left-0 right-0"
              } flex justify-center p-4 ${
                isLandscape ? "" : "bg-gradient-to-t from-black to-transparent"
              } z-10`}
            >
              <button
                className="bg-white text-indigo-700 px-6 py-2 rounded-full text-lg font-semibold shadow-lg transition-all duration-300 hover:bg-indigo-100"
                onClick={toggleImage}
              >
                {activeImage === "before"
                  ? t("imageComparison.modal.viewAfter")
                  : t("imageComparison.modal.viewBefore")}
              </button>
            </div>

            <AnimatePresence>
              {showRotationHint && !isLandscape && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{ duration: 0.3 }}
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70 z-20"
                >
                  <div className="text-white text-center p-4">
                    <motion.div
                      animate={{ rotate: 90 }}
                      transition={{ duration: 1, ease: "easeInOut" }}
                    >
                      <svg
                        className="w-16 h-16 mx-auto"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.34 6.41L.86 12.9l6.49 6.48 6.49-6.48-6.5-6.49zM3.69 12.9l3.66-3.66L11 12.9l-3.66 3.66-3.65-3.66zm15.67-6.26C17.61 4.88 15.3 4 13 4V.76L8.76 5 13 9.24V6c1.79 0 3.58.68 4.95 2.05 2.73 2.73 2.73 7.17 0 9.9C16.58 19.32 14.79 20 13 20c-.97 0-1.94-.21-2.84-.61l-1.49 1.49C10.02 21.62 11.51 22 13 22c2.3 0 4.61-.88 6.36-2.64 3.52-3.51 3.52-9.21 0-12.72z" />
                      </svg>
                    </motion.div>
                    <p className="text-xl mt-2">
                      {t("imageComparison.rotationHint")}
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageComparison;

// Now it works!
//  Agin, this is the
