import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "../hooks/useMediaQuery";

const SectionHero = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [sliderPosition, setSliderPosition] = useState(50);
  const sliderRef = useRef(null);

  const handleMouseMove = (event) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
      setSliderPosition((x / rect.width) * 100);
    }
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    handleMouseMove(event.touches[0]);
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("touchmove", handleTouchMove);
    }
    return () => {
      if (slider) {
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  const beforeOpacity = Math.min(1, sliderPosition / 50);
  const afterOpacity = Math.min(1, (100 - sliderPosition) / 50);

  const CTA = () => (
    <div className="mt-6 sm:mt-8">
      <a
        href="#pricing"
        className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-4 py-2.5 text-m font-semibold text-white shadow-sm hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
      >
        {t("sectionHero.cta")}
      </a>
    </div>
  );

  const Testimonial = () => (
    <figure className="mt-8 lg:mt-16 border-l border-gray-300 pl-4 lg:pl-8 text-gray-600">
      <blockquote className="text-sm lg:text-base leading-6 lg:leading-7">
        <p>{t("sectionHero.testimonial.quote")}</p>
      </blockquote>
      <figcaption className="mt-4 lg:mt-6 flex gap-x-4 text-sm leading-6">
        <div>
          <span className="font-semibold text-gray-800">
            {t("sectionHero.testimonial.author")}
          </span>{" "}
          – {t("sectionHero.testimonial.company")}
        </div>
      </figcaption>
    </figure>
  );

  return (
    <section className="overflow-hidden bg-gray-50 text-gray-800 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-12 lg:gap-y-16 lg:max-w-none lg:grid-cols-2 lg:items-start">
          <div className="lg:pr-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mb-6">
                <span className="text-blue-600">
                  {t("sectionHero.title.part1")}
                </span>{" "}
                {t("sectionHero.title.part2")}
              </h1>

              <div className="space-y-4">
                <p className="text-base sm:text-lg leading-7 sm:leading-8 text-gray-700">
                  {t("sectionHero.description.part1")}
                </p>
                <p className="text-base sm:text-lg leading-7 sm:leading-8 text-gray-700">
                  {t("sectionHero.description.part2")}
                </p>
              </div>

              {!isMobile && (
                <>
                  <CTA />
                  <Testimonial />
                </>
              )}
            </div>
          </div>

          <div className="lg:order-last">
            <div
              className="relative w-full h-[400px] sm:h-[500px] rounded-xl overflow-hidden"
              ref={sliderRef}
            >
              <img
                alt="Before"
                src="/images/before/hero_original.jpg"
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
              <div
                className="absolute top-0 left-0 w-full h-full overflow-hidden"
                style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }}
              >
                <img
                  alt="After"
                  src="/images/after/hero_after.png"
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
              </div>
              <div
                className="absolute top-0 bottom-0 w-1 bg-white cursor-ew-resize"
                style={{ left: `${sliderPosition}%` }}
              >
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 bg-white rounded-full flex items-center justify-center">
                  <svg
                    className="w-4 h-4 text-blue-600"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                    <path d="M15.41 16.59L10.83 12 15.41 7.41 14 6l-6 6 6 6 1.41-1.41z" />
                  </svg>
                </div>
              </div>
              <div
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white text-blue-600 px-2 sm:px-4 py-1 sm:py-2 rounded text-sm sm:text-lg font-semibold transition-opacity duration-200"
                style={{ opacity: beforeOpacity }}
              >
                {t("imageComparison.before")}
              </div>
              <div
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white text-blue-600 px-2 sm:px-4 py-1 sm:py-2 rounded text-sm sm:text-lg font-semibold transition-opacity duration-200"
                style={{ opacity: afterOpacity }}
              >
                {t("imageComparison.after")}
              </div>
            </div>
          </div>

          {isMobile && (
            <div className="lg:hidden">
              <Testimonial />
              <CTA />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
