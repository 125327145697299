import React from "react";
import { useTranslation } from "react-i18next";
import { FaQuoteLeft } from "react-icons/fa";
import ImageComparison from "../common/ImageComparison";

const SectionImageExamples = () => {
  const { t } = useTranslation();
  return (
    <section
      id="examples"
      className="bg-gradient-to-b from-indigo-50 to-white py-16 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto">
        <p className="text-base font-semibold leading-7 text-indigo-600 text-center">
          {t("shortTermRental.sectionImageExamples.subtitle")}
        </p>
        <h2 className="text-3xl font-bold text-center text-indigo-700 mb-12">
          {t("shortTermRental.sectionImageExamples.title")}
        </h2>
        <div className="space-y-12">
          <ImageComparison
            beforeSrc="/images/before/interior_original.jpg"
            afterSrc="/images/after/interior_after.png"
            title="shortTermRental.sectionImageExamples.interior.title"
            description="shortTermRental.sectionImageExamples.interior.description"
          />

          {/* <div
            className={`grid ${
              isDesktop ? "grid-cols-2" : "grid-cols-1"
            } gap-8`}
          > */}
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/exterior_original.jpg"
              afterSrc="/images/after/exterior_after.png"
              title="shortTermRental.sectionImageExamples.exterior.title"
              description="shortTermRental.sectionImageExamples.exterior.description"
            />
          </div>
          <div className="mt-24 mb-24 text-center">
            <h3 className="text-2xl font-bold text-indigo-700 mb-4">
              {t("shortTermRental.sectionImageExamples.cta.title")}
            </h3>
            <p className="text-lg text-gray-600 mb-6">
              {t("shortTermRental.sectionImageExamples.cta.subtitle")}
            </p>
            <a
              href="/free-trial"
              className="inline-flex rounded-md bg-gradient-to-r from-indigo-500 to-indigo-700 px-6 py-3 text-lg font-semibold text-white shadow-md hover:from-indigo-600 hover:to-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300"
            >
              {t("shortTermRental.sectionImageExamples.cta.button")}
            </a>
          </div>
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/blurry_original.jpg"
              afterSrc="/images/after/blurry_after.png"
              title="shortTermRental.sectionImageExamples.blurry.title"
              description="shortTermRental.sectionImageExamples.blurry.description"
            />
          </div>
          <div className="w-full">
            <ImageComparison
              beforeSrc="/images/before/landscape_original.jpg"
              afterSrc="/images/after/landscape_after.png"
              title="shortTermRental.sectionImageExamples.landscape.title"
              description="shortTermRental.sectionImageExamples.landscape.description"
            />
          </div>
        </div>
      </div>
      {/* Testimonial */}
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl mx-auto my-24">
        <div className="flex items-start mb-4">
          <FaQuoteLeft className="text-indigo-500 text-4xl mr-4 flex-shrink-0" />
          <p className="text-lg text-gray-700 italic">
            "{t("shortTermRental.sectionImageExamples.testimonial.quote")}"
          </p>
        </div>
        <div className="flex items-center justify-end">
          <div className="text-right">
            <p className="text-base font-semibold text-gray-900">
              {t("shortTermRental.sectionImageExamples.testimonial.author")}
            </p>
            <div className="flex items-center mt-1">
              {[...Array(5)].map((_, i) => (
                <svg
                  key={i}
                  className="w-4 h-4 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionImageExamples;
