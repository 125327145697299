import React from "react";
import SectionSteps from "./ShortRentalSteps";
import SectionHero from "./ShortRentalHero";
import SectionComparison from "./ShortRentalComparison";
import SectionPricing from "./ShortRentalPricing";
import SectionImageExamples from "./ShortRentalImageExamples";
import SectionFAQ from "./ShortRentalFAQ";
import SectionCTA from "./ShortRentalCTA";

function Landing() {
  return (
    <>
      <div>
        <SectionHero />
        <SectionSteps />
        <SectionImageExamples />
        <SectionComparison />
        <SectionPricing />
        <SectionFAQ />
        <SectionCTA />
      </div>
    </>
  );
}

export default Landing;
