import React, { useState, useEffect } from "react";
import ImageComparison from "../common/ImageComparison";

const Img2Img = () => {
  const [imagePairs, setImagePairs] = useState([]);
  const [comparisonType, setComparisonType] = useState("v1vsv2");

  useEffect(() => {
    // In a real scenario, you'd fetch this list from your server or file system
    // For this example, we'll use a hardcoded list of image pairs
    const testImagePairs = [
      {
        before: "image1.png",
        after: "image1.png",
        title: "Telmo Image 1",
      },
      {
        before: "image2.png",
        after: "image2.png",
        title: "Telmo Image 2",
      },
      {
        before: "image3.png",
        after: "image3.png",
        title: "Telmo Image 3",
      },
      {
        before: "image4.png",
        after: "image4.png",
        title: "Telmo Image 4",
      },
      {
        before: "image5.png",
        after: "image5.png",
        title: "Telmo Image 5",
      },
      {
        before: "image6.png",
        after: "image6.png",
        title: "Telmo Image 6",
      },
    ];
    setImagePairs(testImagePairs);
  }, []);

  const getImageSources = (pair) => {
    switch (comparisonType) {
      case "v1vsv2":
        return {
          beforeSrc: `/images/tests/v1/${pair.before}`,
          afterSrc: `/images/tests/v2/${pair.after}`,
        };
      case "originalvsv1":
        return {
          beforeSrc: `/images/tests/original/${pair.before.replace(
            ".png",
            ".jpg"
          )}`,
          afterSrc: `/images/tests/v1/${pair.before}`,
        };
      case "originalvsv2":
        return {
          beforeSrc: `/images/tests/original/${pair.before.replace(
            ".png",
            ".jpg"
          )}`,
          afterSrc: `/images/tests/v2/${pair.after}`,
        };
      default:
        return {
          beforeSrc: `/images/tests/v1/${pair.before}`,
          afterSrc: `/images/tests/v2/${pair.after}`,
        };
    }
  };

  return (
    <div className="container mx-auto px-4 pb-8">
      <div className="sticky top-0 bg-white z-10 py-4 shadow-md">
        <h1 className="text-3xl font-bold text-center text-indigo-700 mb-4">
          Image to Image Test
        </h1>
        <div className="max-w-md mx-auto">
          <label htmlFor="comparisonType" className="block mb-2 font-bold">
            Comparison Type:
          </label>
          <select
            id="comparisonType"
            value={comparisonType}
            onChange={(e) => setComparisonType(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="v1vsv2">V1 vs V2</option>
            <option value="originalvsv1">Original vs V1</option>
            <option value="originalvsv2">Original vs V2</option>
          </select>
        </div>
      </div>
      <div className="space-y-12 mt-8">
        {imagePairs.map((pair, index) => {
          const { beforeSrc, afterSrc } = getImageSources(pair);
          return (
            <div key={index} className="w-full">
              <ImageComparison
                beforeSrc={beforeSrc}
                afterSrc={afterSrc}
                title={pair.title || `Test Image ${index + 1}`}
                description="This is a test image for the Img2Img component."
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Img2Img;
