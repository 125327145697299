import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const PricingSection = () => {
  const { t } = useTranslation();
  const [showGuarantee, setShowGuarantee] = useState(false);

  // Get the plans array from translations
  const plans = t("sectionPricing.plans", { returnObjects: true });

  return (
    <section
      id="pricing"
      className="bg-gradient-to-b from-gray-50 to-blue-100 py-16 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">
          {t("sectionPricing.title")}
        </h2>
        <p className="text-xl text-center text-gray-700 mb-6 max-w-3xl mx-auto">
          {t("sectionPricing.description")}
          <b>{t("sectionPricing.bold_description")}</b>
        </p>

        <div className="text-center mb-8">
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowGuarantee(!showGuarantee)}
          >
            {t("sectionPricing.guaranteeButton")}
            <svg
              className={`ml-2 h-5 w-5 transform ${
                showGuarantee ? "rotate-180" : ""
              } transition-transform duration-200`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {showGuarantee && (
          <div className="bg-white border border-gray-200 rounded-lg p-4 mb-8 text-sm text-gray-700 max-w-3xl mx-auto">
            {t("sectionPricing.guaranteeText")}
          </div>
        )}

        <div className="grid md:grid-cols-4 gap-8 mt-12">
          {Array.isArray(plans) &&
            plans.map((plan, index) => (
              <div
                key={index}
                className={`bg-white rounded-lg shadow-lg p-6 border-2 ${
                  plan.highlight
                    ? "border-indigo-500 relative overflow-hidden"
                    : "border-blue-300"
                }`}
              >
                {plan.highlight && (
                  <div className="absolute top-0 right-0 bg-indigo-500 text-white text-xs font-bold px-3 py-1 rounded-bl-lg">
                    {t("sectionPricing.mostPopular")}
                  </div>
                )}
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {plan.name}
                </h3>
                <div className="text-4xl font-bold text-gray-900 mb-4">
                  {plan.price === 0 ? "Free" : `€${plan.price}`}
                </div>
                <ul className="space-y-2 mb-6">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-700">
                      <svg
                        className="w-5 h-5 mr-2 text-indigo-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                {plan.price === 0 ? (
                  <a
                    href={plan.paymentUrl}
                    className={`w-full py-2 px-4 rounded-md font-semibold text-white transition-all duration-300 text-center block ${
                      plan.highlight
                        ? "bg-indigo-500 hover:bg-indigo-600"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                  >
                    {plan.cta}
                  </a>
                ) : (
                  <button
                    className={`w-full py-2 px-4 rounded-md font-semibold text-white transition-all duration-300 ${
                      plan.highlight
                        ? "bg-indigo-500 hover:bg-indigo-600"
                        : "bg-blue-600 hover:bg-blue-700"
                    }`}
                    onClick={() => (window.location.href = plan.paymentUrl)}
                  >
                    {plan.cta}
                  </button>
                )}
              </div>
            ))}
        </div>

        <p className="text-center text-gray-600 mt-8 text-sm">
          {t("sectionPricing.disclaimer")}
        </p>
      </div>
    </section>
  );
};

export default PricingSection;
