import React from "react";
import { Routes, Route } from "react-router-dom";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import GoogleAnalytics from "./components/common/GoogleAnalytics";
import Hotjar from "./components/common/Hotjar";
import ScrollToTop from "./components/common/ScrollToTop";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import TermsAndServices from "./components/Legal/Terms";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import Home from "./components/Home/Home";
import Refund from "./components/Legal/Refund";
import ImageUpload from "./components/Home/ImageUpload";
import FreePackageUpload from "./components/Home/FreePackageUpload";
import NotFound from "./components/Home/NotFound";
import Img2Img from "./components/Tests/Img2img";
import OrderImages from "./components/common/OderImages";
import LandingShortRental from "./components/LandingShortRental/LandingShortRental";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <div className="min-h-screen bg-white flex flex-col">
        <GoogleAnalytics measurementId="G-MW9H3KKBVF" />
        <Header />
        <Hotjar hjid={5129739} hjsv={6} />
        <ScrollToTop />
        <main className="flex-grow pt-2">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/short-term-rental" element={<LandingShortRental />} />
            <Route path="/terms" element={<TermsAndServices />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/tests" element={<Img2Img />} />
            <Route path="/upload/:uploadLink" element={<ImageUpload />} />
            <Route path="/free-trial" element={<FreePackageUpload />} />
            <Route path="/order/:orderId" element={<OrderImages />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </I18nextProvider>
  );
}

export default App;
