import React from "react";
import { useTranslation } from "react-i18next";

const FeatureCard = ({ icon, titleKey, descriptionKey }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg">
      <div className="text-blue-600 mb-4">{icon}</div>
      <h3 className="text-xl font-semibold text-gray-800 mb-2">
        {t(titleKey)}
      </h3>
      <p className="text-gray-600">{t(descriptionKey)}</p>
    </div>
  );
};

const SectionCTA = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gradient-to-b from-blue-100 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          {/* <p className="text-sm font-semibold text-blue-600 mb-2">
            {t("sectionCTA.subtitle")}
          </p> */}
          <h2 className="text-3xl md:text-4xl font-bold text-blue-900 mb-4">
            {t("sectionCTA.title")}
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            {t("sectionCTA.description")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <FeatureCard
            icon={
              <svg
                className="w-12 h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                />
              </svg>
            }
            titleKey="sectionCTA.features.authenticity.title"
            descriptionKey="sectionCTA.features.authenticity.description"
          />
          <FeatureCard
            icon={
              <svg
                className="w-12 h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clipRule="evenodd"
                />
              </svg>
            }
            titleKey="sectionCTA.features.privacy.title"
            descriptionKey="sectionCTA.features.privacy.description"
          />
          <FeatureCard
            icon={
              <svg
                className="w-12 h-12"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            }
            titleKey="sectionCTA.features.satisfaction.title"
            descriptionKey="sectionCTA.features.satisfaction.description"
          />
        </div>

        <div className="text-center">
          <a
            href="#pricing"
            className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-3 text-m font-semibold text-white shadow-md hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
          >
            {t("sectionCTA.ctaButton")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionCTA;
