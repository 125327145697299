import React from "react";
import { useTranslation } from "react-i18next";

const SectionComparison = () => {
  const { t } = useTranslation();

  const aiSteps = t("shortTermRental.sectionComparison.aiSteps", {
    returnObjects: true,
  });
  const traditionalSteps = t(
    "shortTermRental.sectionComparison.traditionalSteps",
    { returnObjects: true }
  );

  return (
    <section className="bg-gradient-to-b from-blue-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-blue-700 mb-6">
          {t("shortTermRental.sectionComparison.title")}
        </h2>
        <p className="text-center text-gray-700 mb-12 max-w-3xl mx-auto">
          {t("shortTermRental.sectionComparison.description")}
        </p>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white rounded-lg shadow-lg p-6 border-2 border-blue-200">
            <h3 className="text-xl font-semibold text-blue-800 mb-4">
              {t("shortTermRental.sectionComparison.aiService.title")}
            </h3>
            <ol className="space-y-4">
              {aiSteps.map((step, index) => (
                <li key={index} className="flex items-start">
                  <span className="flex-shrink-0 w-8 h-8 bg-blue-600 text-white rounded-full flex items-center justify-center mr-3">
                    {index + 1}
                  </span>
                  <div>
                    <h4 className="font-semibold text-blue-700">
                      {step.title}{" "}
                      <span className="font-normal text-blue-500">
                        ({step.time})
                      </span>
                    </h4>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </li>
              ))}
            </ol>
          </div>

          <div className="bg-gray-100 rounded-lg shadow-lg p-6 border-2 border-gray-200">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              {t("shortTermRental.sectionComparison.traditional.title")}
            </h3>
            <ul className="space-y-2">
              {traditionalSteps.map((step, index) => (
                <li key={index} className="flex items-center">
                  <svg
                    className="w-5 h-5 mr-2 text-red-500 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                  <span className="text-gray-700">{step}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="mt-12 text-center">
          <a
            href="#pricing"
            className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-3 text-lg font-semibold text-white shadow-sm hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
          >
            {t("shortTermRental.sectionComparison.cta")}
          </a>
        </div>
      </div>
    </section>
  );
};

export default SectionComparison;
