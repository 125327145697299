import React from "react";
import { useTranslation } from "react-i18next";
import { FiTrendingUp, FiClock, FiDollarSign } from "react-icons/fi";

const BenefitItem = ({ icon, stat, text }) => (
  <div className="bg-white rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg">
    <div className="text-blue-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold text-gray-800 mb-2">{stat}</h3>
    <p className="text-gray-600">{text}</p>
  </div>
);

const SectionWhyUs = () => {
  const { t } = useTranslation();

  const benefits = t("sectionWhyUs.benefits", { returnObjects: true });

  return (
    <section className="bg-gradient-to-b from-blue-50 to-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-blue-900 mb-4">
            {t("sectionWhyUs.title")}
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          <BenefitItem
            icon={<FiTrendingUp className="w-12 h-12" />}
            stat={benefits[0].stat}
            text={benefits[0].text}
          />
          <BenefitItem
            icon={<FiClock className="w-12 h-12" />}
            stat={benefits[1].stat}
            text={benefits[1].text}
          />
          <BenefitItem
            icon={<FiDollarSign className="w-12 h-12" />}
            stat={benefits[2].stat}
            text={benefits[2].text}
          />
        </div>

        <div className="text-center mb-16">
          <b>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-6">
              {t("sectionWhyUs.tryItOut")}
            </p>
          </b>
          <a
            href="#pricing"
            className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-3 text-m font-semibold text-white shadow-md hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
          >
            {t("sectionWhyUs.cta")}
          </a>
          <p className="text-xs text-gray-500 mt-4 italic">
            {t("sectionWhyUs.benefitsSource")}
          </p>
        </div>
      </div>
    </section>
  );
};

export default SectionWhyUs;
