import React, { useState, useEffect, Suspense, lazy } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useInView } from "react-intersection-observer";
import { supabase } from "../api/supabaseClient";

const LazyImageComparison = lazy(() => import("./ImageComparison"));

const OrderImages = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);

  useEffect(() => {
    const fetchImageManifest = async () => {
      try {
        setIsLoading(true);
        const manifestResponse = await fetch(
          `/images/demos/${orderId}/images.json`
        );
        if (!manifestResponse.ok) {
          throw new Error("Failed to load image manifest");
        }
        const manifest = await manifestResponse.json();

        // Transform manifest data into the format expected by the component
        const imageSet = manifest.images.map((img, index) => ({
          before: `/images/demos/${orderId}/${img.before}`,
          after: `/images/demos/${orderId}/${img.after}`,
          title: t("freeOrderImages.imageTitle", { number: index + 1 }),
        }));

        setImages(imageSet);

        // Preload the first two images
        const preloadImages = imageSet.slice(0, 2).map((img) => {
          return new Promise((resolve) => {
            const image = new Image();
            image.onload = resolve;
            image.src = img.after;
          });
        });

        await Promise.all(preloadImages);
        setFirstImageLoaded(true);
      } catch (error) {
        console.error("Error loading image manifest:", error);
        setError(t("freeOrderImages.errors.loadingManifest"));
      } finally {
        setIsLoading(false);
      }
    };

    fetchImageManifest();
  }, [orderId, t]);

  const downloadImages = async () => {
    setIsDownloading(true);
    const zip = new JSZip();

    try {
      // Create the zip file
      await Promise.all(
        images.map(async (image, index) => {
          const response = await fetch(image.after);
          const blob = await response.blob();
          zip.file(`image${index + 1}_after.png`, blob);
        })
      );

      const content = await zip.generateAsync({ type: "blob" });

      // Trigger the download
      saveAs(content, `${orderId}_homeshotpro.zip`);

      // Attempt to update the database
      try {
        const { data, error } = await supabase
          .from("orders")
          .update({ images_downloaded: true })
          .or(
            `upload_link.eq.${orderId},upload_link.eq.free-package-${orderId}`
          )
          .select();

        if (error) {
          console.error("Error updating database:", error);
          // You might want to log this error or handle it in some way
        } else {
          console.log("Database updated successfully:", data);
        }
      } catch (dbError) {
        console.error("Failed to update database:", dbError);
        // You might want to log this error or handle it in some way
      }
    } catch (error) {
      console.error("Error creating zip file:", error);
      setError(t("freeOrderImages.errors.zipCreation"));
    } finally {
      setIsDownloading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-indigo-50 to-white min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-center text-indigo-700 mb-6">
          {t("freeOrderImages.title")}
        </h1>
        <p className="text-center text-gray-600 mb-8">
          {t("freeOrderImages.description")}
        </p>
        {error && <div className="text-red-500 text-center mt-8">{error}</div>}
        <div className="text-center mb-8">
          <p className="text-gray-700 mb-4">
            {t("freeOrderImages.downloadPrompt")}
          </p>
          <button
            onClick={downloadImages}
            disabled={isDownloading}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
          >
            {isDownloading
              ? t("freeOrderImages.downloadingButton")
              : t("freeOrderImages.downloadButton")}
          </button>
        </div>
        <div className="space-y-12">
          {!firstImageLoaded && (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-indigo-500"></div>
            </div>
          )}
          {images.map((image, index) => (
            <LazyLoadedImage key={index} image={image} index={index} />
          ))}
        </div>
        <div className="text-center mt-12">
          <p className="text-gray-700 mb-4">
            {t("freeOrderImages.enhanceMorePrompt")}
          </p>
          <a
            href="/#pricing"
            className="inline-flex rounded-md bg-gradient-to-r from-blue-500 to-blue-700 px-3 py-1.5 text-lg font-semibold text-white shadow-sm hover:from-blue-600 hover:to-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 transition-all duration-300"
          >
            {t("freeOrderImages.viewPricingButton")}
          </a>
        </div>
      </div>
    </div>
  );
};

const LazyLoadedImage = ({ image, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "200px 0px",
  });

  return (
    <div ref={ref}>
      {(index < 2 || inView) && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyImageComparison
            beforeSrc={image.before}
            afterSrc={image.after}
            title={image.title}
          />
        </Suspense>
      )}
    </div>
  );
};

export default OrderImages;
